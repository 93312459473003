import React from 'react';
import AllureForm from '../components/AllureForm';
import AllureLogo from '../components/AllureLogo';
import { RileyLogo } from '../components/RileyLogo'
const KlaviyoFormPage = () => {
  return (
    <div className="klaviyo_form h-full w-full flex">
        <div className="klaviyo_form_container md:container mx-auto flex flex-col items-center justify-center">
         <div className="form_logo_colaboration flex">
            <AllureLogo/> 
           <span className="allure_qr_x">X</span>
           <RileyLogo/>
         </div>
           <h2 className="klaviyo_form_subtitle">Enter below to receive a</h2>
           <AllureForm/>
        </div>
    </div>      
  );
}


export default KlaviyoFormPage;