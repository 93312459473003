import React from 'react';
import RileyLogoSVG from '../images/riley2.png';
export const RileyLogo = () => {
  return (
    <div>
        <img className='riley_logo' src={RileyLogoSVG} alt="Riley" />
    </div>
  );
}

