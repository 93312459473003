import React from 'react';
import AllureLogoSVG from '../images/allure-logo.svg';

const AllureLogo = () => {
  return (
    <div className='allure_log'>
        <img className='allure_logo_image' src={AllureLogoSVG} alt="Allure" />
    </div>
  );
}

export default AllureLogo;
